import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService,
  combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable, catchError, of } from 'rxjs';

import { ISetPasswordResponse } from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class AccountsService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().accounts;
  }

  constructor(
         protected appService: AppService,
         protected http: HttpClient) {
    super();
  }

  public setPassword(data: any): Observable<ISetPasswordResponse | string> {
    return this.http.post<ISetPasswordResponse>(this.url, data, {headers:combineHeadersOptions(commonErrorHandlingDisabled)}).pipe(
      catchError((error: any) => {
        return of(error);
      })
    );
  }
}
